var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: "注音",
        maskClosable: false,
        visible: _vm.open,
        width: "600px",
        destroyOnClose: "",
      },
      on: { ok: _vm.save, cancel: _vm.cancel },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "a-spin",
        {
          attrs: { spinning: _vm.loading },
          on: { mouseover: _vm.topCenterMouseover },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
              },
            },
            [
              _c(
                "a-radio-group",
                {
                  staticStyle: { width: "50%" },
                  on: { change: _vm.radioChange },
                  model: {
                    value: _vm.radioVluae,
                    callback: function ($$v) {
                      _vm.radioVluae = $$v
                    },
                    expression: "radioVluae",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "single" } }, [
                    _vm._v("单字注音"),
                  ]),
                  _c("a-radio", { attrs: { value: "multiple" } }, [
                    _vm._v("合并后注音"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.rePhonetic
                    ? _c(
                        "a-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.radioChange },
                        },
                        [_vm._v("重新获取注音")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "line-height": "40px", "margin-top": "12px" } },
            [
              _c("a-col", { attrs: { span: 10 } }, [_vm._v("文字")]),
              _c("a-col", { attrs: { span: 10 } }, [_vm._v("注音")]),
              _c("a-col", { attrs: { span: 4 } }),
            ],
            1
          ),
          _vm._l(_vm.phoneticTextList, function (item, index) {
            return _c(
              "a-row",
              {
                key: index,
                staticStyle: { display: "flex", "align-items": "center" },
              },
              [
                _c(
                  "a-col",
                  {
                    staticStyle: {
                      "line-height": "40px",
                      "padding-right": "12px",
                    },
                    attrs: { span: 10 },
                  },
                  [
                    _c("a-input", {
                      attrs: {
                        maxLength: _vm.radioVluae == "single" ? 1 : 100,
                        placeholder: "请输入",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.keyBlur(item)
                        },
                      },
                      model: {
                        value: item.key,
                        callback: function ($$v) {
                          _vm.$set(item, "key", $$v)
                        },
                        expression: "item.key",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  {
                    staticStyle: {
                      "line-height": "40px",
                      "padding-right": "12px",
                    },
                    attrs: { span: 10 },
                  },
                  [
                    _c(
                      "a-spin",
                      { attrs: { spinning: _vm.inputLoading } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("a-col", { attrs: { span: 4 } }, [
                  _vm.radioVluae == "single"
                    ? _c(
                        "div",
                        { staticClass: "end-btns-table" },
                        [
                          _c("a-button", {
                            staticClass: "item-btn",
                            attrs: {
                              disabled: _vm.phoneticTextList.length == 1,
                              shape: "circle",
                              size: "small",
                              icon: "minus",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delItem(index)
                              },
                            },
                          }),
                          _c("a-button", {
                            staticClass: "item-btn",
                            attrs: {
                              shape: "circle",
                              size: "small",
                              icon: "plus",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.addItem(index)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { on: { mouseover: _vm.topCenterMouseover } },
          [
            _c("a-button", { key: "back", on: { click: _vm.cancel } }, [
              _vm._v("\n        取消\n      "),
            ]),
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { type: "primary" },
                on: { click: _vm.save },
              },
              [_vm._v("\n        确定\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }